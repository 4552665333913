import { type ComponentType, createContext, type ReactNode, useContext, useEffect, useState } from "react";

import { type AccountManagersCompany } from "@doitintl/cmp-models";
import axios from "axios";
import {
  type Auth,
  type IdTokenResult,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
} from "firebase/auth";
import merge from "lodash/merge";
import { getDisplayName } from "recompose";
import type { SetNonNullable, SetRequired } from "type-fest";

import { auth, initFirebaseAuth } from "../utils/firebase";
import { usePrevious } from "../utils/usePrevious";
import { addAuthWindowFunction } from "../utils/windowInit";
import { type FirebaseUserWithEmail } from "./UserContext";

type Impersonate = {
  email: string;
  active: boolean;
};

type AuthContextType = {
  currentUser?: FirebaseUserWithEmail | null;
  token?: IdTokenResult | null;
  tokenValue?: string | null;
  auth?: Auth;
  uservoiceSSO?: string;
  impersonate?: Impersonate;
  authTime?: number;
  domain: string | undefined;
  signInProvider?: string;
};

type FullAuthContextType = AuthContextType & {
  isDoitEmployee?: boolean;
  isDoitOwner?: boolean;
  isDoitDeveloper?: boolean;
  customerId?: string;
  userId?: string;
  providerLoginId?: string;
} & (
    | {
        isDoitPartner: false | undefined;
        partnerCompany?: undefined;
      }
    | {
        isDoitPartner: true;
        partnerCompany: Exclude<AccountManagersCompany, "doit">;
      }
  );

type FullAuthContextTypeWithUser = SetNonNullable<
  SetRequired<
    FullAuthContextType,
    "isDoitEmployee" | "isDoitOwner" | "isDoitDeveloper" | "userId" | "currentUser" | "token" | "tokenValue"
  >,
  "currentUser" | "token"
>;

const defaultValues: FullAuthContextType = {
  isDoitEmployee: undefined,
  isDoitOwner: undefined,
  isDoitPartner: undefined,
  isDoitDeveloper: undefined,
  impersonate: undefined,
  tokenValue: undefined,
  domain: undefined,
  signInProvider: undefined,
};

const authContext = createContext<FullAuthContextType>({ ...defaultValues });

export function useAuthContext(): FullAuthContextType;
export function useAuthContext({ mustHaveUser }: { mustHaveUser: true }): FullAuthContextTypeWithUser;

export function useAuthContext(
  { mustHaveUser }: { mustHaveUser?: boolean } = {
    mustHaveUser: false,
  }
): Partial<FullAuthContextType> {
  const authContextValue = useContext(authContext);

  if (mustHaveUser && (!authContextValue.token || !authContextValue.currentUser)) {
    throw new Error("request auth context before login");
  }

  return authContextValue;
}

const extractTokenData = (tokenClaims?: Record<string, any> | null) => ({
  isDoitEmployee: tokenClaims?.doitEmployee ?? false,
  impersonate: tokenClaims?.impersonate,
  isDoitOwner: tokenClaims?.doitOwner ?? false,
  isDoitPartner: tokenClaims?.doitPartner ?? false,
  isDoitDeveloper: tokenClaims?.doitDeveloper ?? false,
  customerId: tokenClaims?.customerId,
  partnerCompany: tokenClaims?.partnerCompany,
  userId: tokenClaims?.userId,
  authTime: tokenClaims?.auth_time ? parseInt(tokenClaims?.auth_time, 10) : undefined,
  domain: tokenClaims?.domain,
  signInProvider: tokenClaims?.firebase?.sign_in_provider,
  providerLoginId: tokenClaims?.user_id,
});

export const AuthContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<{
    currentUser: Partial<FirebaseUserWithEmail> | null;
    token: Partial<IdTokenResult> | null;
  }>;
}) => {
  const defaultAuth = {
    currentUser: {},
    token: {},
  };

  const mergedAuth = merge({}, defaultAuth, value ?? {});

  const currentUser = mergedAuth.currentUser;
  const actualToken = mergedAuth.token;

  if (actualToken && !actualToken.claims) {
    actualToken.claims = {};
  }

  return (
    // the Provider gives access to the context to its children

    <authContext.Provider
      value={
        {
          ...extractTokenData(mergedAuth.token.claims),
          ...value,
          currentUser: { metadata: {}, ...currentUser },
          token: actualToken,
          tokenValue: actualToken.token,
        } as any
      }
    >
      {children}
    </authContext.Provider>
  );
};

export const AuthContextProvider = ({ children }: { children?: ReactNode }) => {
  const [authState, setAuthState] = useState<FullAuthContextType>({
    ...defaultValues,
  });

  function stateFromToken(
    currentUser: FirebaseUserWithEmail | null,
    currentToken: IdTokenResult | null
  ): FullAuthContextType {
    return {
      ...extractTokenData(currentToken?.claims),
      currentUser,
      token: currentToken,
      auth,
      tokenValue: currentToken?.token,
    };
  }

  useEffect(() => {
    initFirebaseAuth();
  }, []);

  useEffect(
    () =>
      auth.onAuthStateChanged(async (user) => {
        const currentToken = (await user?.getIdTokenResult()) ?? null;
        setAuthState(stateFromToken(user as FirebaseUserWithEmail, currentToken));
      }),
    []
  );

  useEffect(
    () =>
      auth.onIdTokenChanged(async (user) => {
        const currentToken = (await user?.getIdTokenResult()) ?? null;

        if (currentToken?.token !== authState.token?.token) {
          setAuthState(stateFromToken(user as FirebaseUserWithEmail, currentToken));
        }
      }),
    [authState]
  );

  useEffect(() => {
    if (!authState.token?.token) {
      return;
    }

    axios
      .request({
        method: "get",
        url: "/auth/uservoice",
        headers: {
          Authorization: `Bearer ${authState?.token.token}`,
        },
      })
      .then((res) => {
        setAuthState((prevState) => ({
          ...prevState,
          uservoiceSSO: res.data.token,
        }));
      });
  }, [authState.token?.token]);

  return (
    // the Provider gives access to the context to its children
    <authContext.Provider value={authState}>{children}</authContext.Provider>
  );
};

type AuthStateChangeCallback = (user: FirebaseUserWithEmail | null, token: IdTokenResult | null) => any;

export const AuthSubscribe = ({ onAuthStateChanged }: { onAuthStateChanged: AuthStateChangeCallback }) => {
  const { currentUser, token, auth } = useAuthContext();
  const prevCurrentUser = usePrevious(currentUser);

  useEffect(() => {
    if (token !== undefined && currentUser !== undefined && prevCurrentUser !== currentUser) {
      onAuthStateChanged(currentUser, token);

      localStorage.setItem("clouddiagrams/user", JSON.stringify(currentUser));
    }
  }, [currentUser, prevCurrentUser, onAuthStateChanged, token]);

  useEffect(() => {
    if (auth) {
      const signInWithEmailAndPassword = async ({ email, password, tenantId }) => {
        auth.tenantId = tenantId;
        await firebaseSignInWithEmailAndPassword(auth, email, password);
      };
      const signOut = () => auth.signOut();
      addAuthWindowFunction({ signInWithEmailAndPassword, signOut });
    }
  }, [auth]);

  return null;
};

export const AuthContextConsumer = authContext.Consumer;

type Props = FullAuthContextTypeWithUser;

export type WithAuth = Props;
export type WithToken = {
  token: IdTokenResult;
};

export function withAuth<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent = (props: P) => {
    const authContext = useAuthContext({ mustHaveUser: true });
    return <Component {...authContext} {...props} />;
  };

  WrappedComponent.displayName = `withAuth(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
