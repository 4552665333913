import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  AnalyticsDataSource,
  type BudgetConfig,
  type CloudAnalyticsModelBudgetModel,
  type Collaborator,
  Metric,
  type PublicAccess,
  type SlackChannel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import useEqualityCheck from "../../../../Components/hooks/cloudAnalytics/budgets/useEqualityCheck";
import useAnalyticsUsers from "../../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import {
  useErrorSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useEntitiesContext } from "../../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useAnalyticsContext } from "../../../../Pages/CloudAnalytics/CloudAnalyticsContext";
import { type CurrentLastPeriods } from "../../../../Pages/CloudAnalytics/ReportData";
import Invites from "../../../../Pages/IAM/InviteUserDialog/handleInvite";
import { type Budget, type Budget as BudgetType, type BudgetInfo, type DraftBudget } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { sanitizeDate, TimestampFromDateTime } from "../../../../utils/common";
import mixpanel from "../../../../utils/mixpanel";
import { type CloudAnalyticsHistoryState } from "../../types";
import {
  BudgetConfigurations,
  BudgetDynamicConfigurations,
  BudgetTypes,
  extractTypeAndFrequencyOptionsFromBudget,
  getNewUsers,
  isEditor,
} from "../../utilities";
import { getAttributionScope } from "../../utils/getScope";
import { createNewBudget, updateBudget } from "../db";
import { buildBudgetViewPath, useBudgetRefresh } from "../hooks";
import { getAlertAmountFromPercentage, shareBudget } from "../shared";
import { getBudgetConfiguration } from "../utils";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

type Props =
  | {
      budget: DraftBudget;
      isNewBudget: true;
    }
  | { isNewBudget: false; budget: BudgetType };
const CreateBudgetStepper = ({ budget, isNewBudget }: Props) => {
  const [stepperState, setStepperState] = useState<StepState[]>(["incomplete", "incomplete", "incomplete"]);
  const [currentStep, getCurrentStep] = useState(0);
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [currentLastPeriods, setCurrentLastPeriods] = useState<CurrentLastPeriods>(null);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { filteredAttributions: attributions } = useAttributionsContext();
  const budgetId = useMemo(() => (isNewBudget ? "" : budget.id), [budget, isNewBudget]);
  const [thresholdsAmount, setThresholdsAmount] = useState<number>(0);
  const [budgetInfo, setBudgetInfo] = useState<BudgetInfo>({
    growthPerPeriod: budget.data.config.growthPerPeriod || 0,
    configurationOption: getBudgetConfiguration(budget.data.config),
    name: budget.data.name || "",
    description: budget.data.description || "",
    currentTypeAndFrequency: extractTypeAndFrequencyOptionsFromBudget(
      budget.data.config.type,
      budget.data.config.timeInterval
    ),
    filters: budget.data.config.filters || [],
    dimensionOptions: [],
    startPeriod: budget.data.config.startPeriod
      ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    endPeriod: budget.data.config.endPeriod
      ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    currency: budget.data.config.currency,
    budgetAmount: budget.data.config.amount || 1000,
    alerts: budget.data.config.alerts.map((a) => ({
      ...a,
      amount: getAlertAmountFromPercentage(a.percentage, budget.data.config.amount || 1000),
    })),
    scope: [],
    dataSource: budget.data.config.dataSource ?? AnalyticsDataSource.BILLING,
    rolloverLimit: budget.data.config.rollover?.limit || 0,
    amortizedCost: budget.data.config.amortizedCost || false,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [config, setConfig] = useState<BudgetConfig>(budget.data.config);

  useEffect(() => {
    setBudgetInfo((prev) => ({
      ...prev,
      scope: getAttributionScope(budget.data.config.scope, attributions),
    }));
  }, [budget.data.config.scope, attributions]);
  const [recipients, setRecipients] = useState<string[]>(cloneDeep(budget.data.recipients));
  const [recipientsSlackChannels, setRecipientsSlackChannels] = useState<SlackChannel[]>(
    cloneDeep(budget.data.recipientsSlackChannels) || []
  );
  const [publicAccess, setPublicAccess] = useState<PublicAccess>(null);
  const [newRecipientsSlackChannels, setNewRecipientsSlackChannels] = useState<SlackChannel[]>(recipientsSlackChannels);
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const { entities } = useEntitiesContext();
  const api = useApiContext();
  const { handleMissingPermission } = useAnalyticsContext(); // check if valid
  const { onOpen: showSharedSnackbar } = useSnackbar();

  const isBudgetValid = useMemo(() => {
    const valid =
      (budgetInfo.scope.length > 0 || (budgetInfo.filters?.length ?? 0) > 0) &&
      (budgetInfo.currentTypeAndFrequency.type === BudgetTypes.RECURRING ||
        (budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED &&
          budgetInfo.startPeriod < budgetInfo.endPeriod)) &&
      !!budgetInfo.budgetAmount &&
      budgetInfo.alerts.length > 0 &&
      (recipients.length > 0 || recipientsSlackChannels.length > 0);
    return valid;
  }, [budgetInfo, recipients, recipientsSlackChannels]);

  const {
    loading,
    setLoading,
    handleRefresh,
    budgetStates: { shouldRefreshData },
    dispatchBudgetStates,
  } = useBudgetRefresh({
    isViewPage: false,
    budgetParams: {
      budgetId,
      isBudgetValid,
      isNewBudget,
    },
  });
  const hasRefreshedRef = useRef(false);
  useEffect(() => {
    const refreshIfNeeded = async () => {
      if (budget) {
        if (!budget.data.utilization && !hasRefreshedRef.current) {
          await handleRefresh();

          hasRefreshedRef.current = true;
        }
        setLoading(false);
      }
    };

    refreshIfNeeded();
  }, [budgetId, handleRefresh, budget, setLoading]);

  const { fetchMetadata } = useCloudAnalyticsContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isCurrentUserEditor = isEditor(currentUser.email, budget.data);

  const { customerOrPresentationModeCustomer: customer, customer: genuineCustomer } = useCustomerContext();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${genuineCustomer.id}/analytics/budgets`);
  }, [history, genuineCustomer.id]);

  useUpdateEffect(() => {
    dispatchBudgetStates({ type: "shouldRefreshData" });
  }, [
    budgetInfo.currency,
    budgetInfo.budgetAmount,
    budgetInfo.currentTypeAndFrequency.type,
    budgetInfo.currentTypeAndFrequency.period,
    budgetInfo.endPeriod,
    budgetInfo.scope,
    budgetInfo.alerts,
    budgetInfo.startPeriod,
  ]);

  const stepComponents = [
    <Step1
      key="budget-scope"
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
      budget={budget}
    />,
    <Step2
      key="budget-configuration"
      isCurrentUserEditor={isCurrentUserEditor}
      setOverrideStep={setOverrideStep}
      dimensionOptions={[]}
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      currentLastPeriods={currentLastPeriods}
      setCurrentLastPeriods={setCurrentLastPeriods}
      setThresholdsAmount={setThresholdsAmount}
    />,
    <Step3
      key="budget-notification"
      budgetInfo={budgetInfo}
      budget={budget}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
      shouldRefreshData={shouldRefreshData}
      setRecipients={setRecipients}
      setRecipientsSlackChannels={setRecipientsSlackChannels}
      recipients={recipients}
      recipientsSlackChannels={recipientsSlackChannels}
      isNewBudget={isNewBudget}
      currentLastPeriods={currentLastPeriods}
      thresholdsAmount={thresholdsAmount}
      setNewRecipientsSlackChannels={setNewRecipientsSlackChannels}
      setPublicAccess={setPublicAccess}
    />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: budgetTxt.CREATE_BUDGET.STEP_1.TITLE,
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: budgetTxt.CREATE_BUDGET.STEP_2.TITLE,
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: stepComponents[2],
      label: budgetTxt.CREATE_BUDGET.STEP_3.TITLE,
      order: 2,
      required: true,
      state: stepperState[2],
    },
  ];

  useEffect(() => {
    const step1State =
      budgetInfo.name !== "" && (budgetInfo.scope.length > 0 || (budgetInfo.filters?.length ?? 0) > 0)
        ? "complete"
        : "incomplete";
    const step2State = currentStep > 0 && budgetInfo.budgetAmount ? "complete" : "incomplete";
    const step3State = currentStep > 1 && step2State === "complete" ? "complete" : "incomplete";
    setStepperState([step1State, step2State, step3State]);
  }, [budgetInfo, currentStep]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const prepareConfig = useCallback(() => {
    const originalAmount = () => {
      if (isNaN(budgetInfo.budgetAmount)) {
        return 0;
      }
      return budgetInfo.budgetAmount !== budget.data.config.amount
        ? budgetInfo.budgetAmount
        : budget.data.config.originalAmount;
    };

    const allowGrowth =
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH ||
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH;
    const usePrevSpend =
      budgetInfo.configurationOption.type === BudgetConfigurations.DYNAMIC &&
      (budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD ||
        budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH);

    const rolloverEnabled = budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.NEXT_PERIOD_ROLLOVER;

    const config: BudgetConfig = {
      allowGrowth,
      growthPerPeriod: budgetInfo.growthPerPeriod,
      alerts: budgetInfo.alerts.map((a, i) => ({
        percentage: a.percentage,
        triggered: a.triggered,
        forecastedDate: budget.data.config.alerts[i].forecastedDate,
      })),
      amount: budgetInfo.budgetAmount,
      currency: budgetInfo.currency,
      startPeriod: TimestampFromDateTime(budgetInfo.startPeriod),
      timeInterval: budgetInfo.currentTypeAndFrequency.period,
      metric: Metric.COST,
      originalAmount: originalAmount(),
      usePrevSpend,
      dataSource: budget.data.config.dataSource,
      scope: budgetInfo.scope.map((s) => s.ref),
      type: budgetInfo.currentTypeAndFrequency.type,
      filters: budgetInfo.filters,
      rollover: {
        enabled: rolloverEnabled,
        limit: budgetInfo.rolloverLimit,
      },
      amortizedCost: budgetInfo.amortizedCost,
    };

    if (budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED) {
      config.endPeriod = TimestampFromDateTime(budgetInfo.endPeriod);
    }
    return config;
  }, [budget.data.config, budgetInfo]);

  const updateBudgetHandler = async (ref: ModelReference<CloudAnalyticsModelBudgetModel>) => {
    await updateBudget({
      name: budgetInfo.name,
      description: budgetInfo.description,
      config: prepareConfig(),
      ref,
    });

    mixpanel.track("analytics.budgets.create", { budgetId: ref.id });
    history.push(buildBudgetViewPath(customer.id, ref.id));
  };

  const createBudgetHandler = async () => {
    const ref = await createNewBudget({
      name: budgetInfo.name,
      description: budgetInfo.description,
      config: prepareConfig(),
      customer,
      email: currentUser.email,
    });

    mixpanel.track("analytics.budgets.create", { budgetId: ref.id });
    history.push(buildBudgetViewPath(customer.id, ref.id));
  };

  const addNewUsers = useCallback(
    async (collaborators: Collaborator[]) => {
      try {
        const emails = await getNewUsers({
          collaborators,
          users: userEmails,
          invites,
          baseEntity: budget,
          allUsersAndInvites,
        });
        if (emails.length) {
          await Invites.handleInvite({
            newEmails: emails,
            customer,
            currentUser,
            api,
            entities,
          });
        }
      } catch (e) {
        handleMissingPermission(e as string);
      }
    },
    [userEmails, invites, budget, allUsersAndInvites, customer, currentUser, api, entities, handleMissingPermission]
  );

  const handleShareBudget = useCallback(async () => {
    try {
      await shareBudget({
        api,
        customer,
        budget: budget as Budget,
        publicAccess,
        collaborators: budgetInfo.collaborators ?? [],
        recipients,
        recipientsSlackChannels: newRecipientsSlackChannels,
      });
      showSharedSnackbar({ message: "Budget sharing and alerting saved successfully" });
    } catch (error) {
      setRecipients(budget.data.recipients);
      setRecipientsSlackChannels(budget.data.recipientsSlackChannels ?? []);
      consoleErrorWithSentry(error);
    }
  }, [
    api,
    budget,
    budgetInfo.collaborators,
    customer,
    newRecipientsSlackChannels,
    publicAccess,
    recipients,
    showSharedSnackbar,
  ]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (isNewBudget) {
        await createBudgetHandler();
      } else {
        await addNewUsers(budgetInfo.collaborators ?? []);
        await handleShareBudget();
        await updateBudgetHandler(budget.ref);
      }
      await handleRefresh();
      successSnackbar(budgetTxt.SUCCESSFULLY_SAVED);
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(budgetTxt.FAILED_TO_UPDATE);
    } finally {
      setLoading(false);
    }
  };

  useUpdateEffect(() => {
    setConfig(prepareConfig());
  }, [prepareConfig]);

  const equalityCheck = useEqualityCheck({
    current: {
      name: budgetInfo.name,
      description: budgetInfo.description,
      config,
      notificationData: {
        recipients,
        recipientsSlackChannels,
        newRecipientsSlackChannels,
        publicAccess,
      },
    },
    initial: {
      name: budget.data.name,
      description: budget.data.description,
      config: budget.data.config,
      notificationData: {
        recipients: budget.data.recipients,
        recipientsSlackChannels: budget.data.recipientsSlackChannels,
        newRecipientsSlackChannels: budget.data.recipientsSlackChannels,
        publicAccess: budget.data.public,
      },
    },
    draft: Boolean(isNewBudget),
  });

  useEffect(() => {
    setHasUnsavedChanges(!equalityCheck);
  }, [equalityCheck, prepareConfig, budget.data.config]);

  const saveButtonDisabled = useMemo(
    () => loading || !isCurrentUserEditor || !(isBudgetValid && hasUnsavedChanges),
    [loading, isCurrentUserEditor, isBudgetValid, hasUnsavedChanges]
  );

  return (
    <Box>
      <Stepper
        maxWidth={740}
        loading={loading}
        onCancel={handleClose}
        onSubmit={onSubmit}
        steps={steps}
        footerMaxWidth={1080}
        submitButtonLabel={isNewBudget ? budgetTxt.CREATE_BUDGET.SUBMIT_BTN : globalText.SAVE}
        getCurrentStep={getCurrentStep}
        overrideStep={overrideStep}
        backButtonLabel={isNewBudget ? budgetTxt.NEW_BUDGET : budgetTxt.EDIT_BUDGET}
        disableSubmit={saveButtonDisabled}
      />
    </Box>
  );
};

export default CreateBudgetStepper;
