import { type ChangeEvent, Fragment, type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Aggregator, CurrencyCodes, Metric, TimeInterval } from "@doitintl/cmp-models";
import { FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { globalText } from "../../../assets/texts";
import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../Components/DefinitionList";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import useTransforms from "../../../Components/hooks/cloudAnalytics/useTransforms";
import useFormatter from "../../../Components/hooks/useFormatter";
import { MetadataCard } from "../../../Components/MetadataCard";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { BudgetTypes } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import BudgetPerformanceChart from "./BudgetPerformanceChart";
import { type BudgetChartData, type BudgetChartPointData, type BudgetDefinitionListItem } from "./budgetViewTypes";
import { BUDGET_CHART_HEIGHT, DEFAULT_BUDGET_AMOUNT, MAX_DIMENSIONS_NUM } from "./const";
import { useBudgetRefresh } from "./hooks";
import LoadingBudgetCardWrapper from "./LoadingBudgetCardWrapper";
import { getAlertAmountFromPercentage } from "./shared";
import {
  buildRange,
  filterByCurrentDay,
  filterByCurrentMonth,
  filterByCurrentQuarter,
  filterByCurrentWeek,
  filterByCurrentYear,
  formatBudgetValue,
  formatTimeIntervalPhrase,
  getBudgetRowsDates,
  getBudgetRowsFixedDates,
  getCumulativeForecastedRowsValues,
  getCumulativeRowsValues,
  getDataRowsValues,
  getFilteredDatesUpToToday,
  getFixedBudgetTimeRange,
  getFrequencyPhrase,
  getRowsValuesForFixedType,
  getStartAndEndOfMonthUTC,
  getStartAndEndOfPreviousDayUTC,
  getStartAndEndOfQuarterUTC,
  getStartAndEndOfWeekUTC,
  getStartAndEndOfYearUTC,
  isForecastedDateInBudgetRange,
  populateActualsYValues,
  populateForecastedYValues,
  previewAmountByInterval,
  transformTimestampsToCategories,
} from "./utils";
import { ViewBudgetHeader } from "./ViewBudgetHeader";
import type { Budget } from "../../../types";

const previewTimeByInterval = {
  [TimeInterval.DAY]: TimeInterval.HOUR,
  [TimeInterval.WEEK]: TimeInterval.DAY,
  [TimeInterval.MONTH]: TimeInterval.DAY,
  [TimeInterval.QUARTER]: TimeInterval.WEEK,
  [TimeInterval.YEAR]: TimeInterval.MONTH,
};

const rangeByInterval = {
  [TimeInterval.DAY]: getStartAndEndOfPreviousDayUTC(),
  [TimeInterval.WEEK]: getStartAndEndOfWeekUTC(),
  [TimeInterval.MONTH]: getStartAndEndOfMonthUTC(),
  [TimeInterval.QUARTER]: getStartAndEndOfQuarterUTC(),
  [TimeInterval.YEAR]: getStartAndEndOfYearUTC(),
};

const filterForecastedByInterval = {
  [TimeInterval.DAY]: filterByCurrentDay,
  [TimeInterval.WEEK]: filterByCurrentWeek,
  [TimeInterval.MONTH]: filterByCurrentMonth,
  [TimeInterval.QUARTER]: filterByCurrentQuarter,
  [TimeInterval.YEAR]: filterByCurrentYear,
};

export const ViewBudget = () => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const [, budgets] = useBudgets();
  const [transforms] = useTransforms();
  const [budget, setBudget] = useState<Budget | undefined>(undefined);
  const [isBudgetLoading, setIsBudgetLoading] = useState<boolean>(true);

  const { budgetId } = useParams<{ budgetId: string }>();
  const { handleRefresh, loading: loadingRefreshedBudget } = useBudgetRefresh({
    isViewPage: true,
    budgetParams: {
      budgetId,
    },
  });

  const theme = useTheme();
  const { isMobile } = useFullScreen("lg");

  const variancePositiveColor = theme.palette.success.main;
  const varianceNegativeColor = theme.palette.error.main;
  const amount = budget?.data.config.amount || DEFAULT_BUDGET_AMOUNT;
  const alerts = budget?.data.config.alerts.map((alert) => ({
    ...alert,
    amount: getAlertAmountFromPercentage(alert.percentage, amount),
  }));

  const timeInterval = budget?.data.config.timeInterval || TimeInterval.MONTH;
  const actual = budget?.data.utilization?.current || 0;
  const type = budget?.data.config.type;
  const frequency = type === BudgetTypes.RECURRING ? budgetTxt.RECCURING : budgetTxt.FIXED_PERIOD;
  const currency = budget?.data.config.currency || CurrencyCodes.USD;
  const startPeriod = budget?.data.config.startPeriod
    ? DateTime.fromJSDate(budget?.data.config.startPeriod.toDate()).toUTC()
    : sanitizeDate(DateTime.utc());
  const endPeriod = budget?.data.config.endPeriod
    ? DateTime.fromJSDate(budget?.data.config.endPeriod.toDate()).toUTC()
    : sanitizeDate(DateTime.utc());
  const isFixedBudget: boolean = type === BudgetTypes.FIXED;
  const isBudgetEndDateExists = !!budget?.data.config?.endPeriod;
  const budgetDateTerm = `Budget ${isBudgetEndDateExists ? "dates:" : "start date"}`;
  const budgetDates = `${startPeriod.toFormat("d MMM yyyy")}${isBudgetEndDateExists ? ` - ${endPeriod.toFormat("d MMM yyyy")}` : ""}`;
  const forecastedDate = budget?.data.utilization?.forecastedTotalAmountDate;
  const amortizedCost = Boolean(budget?.data.config?.amortizedCost);
  const rows = budget?.data.data?.rows ?? null;
  const forecastRows = budget?.data.data?.forecastRows ?? null;
  const timeIntervalForRows = budget?.data.data?.timeInterval ?? TimeInterval.MONTH;

  const shouldUseForecastRows = forecastRows && Object.keys(forecastRows).length > 0;
  const shouldUseRows = rows && Object.keys(rows).length > 0;

  const fixedBudgetTimeRange = budget ? getFixedBudgetTimeRange(budget, timeInterval) : [];

  const previewTime = timeInterval && previewTimeByInterval[timeInterval];

  const actualDataPreviewAmount = isFixedBudget
    ? getFilteredDatesUpToToday(fixedBudgetTimeRange)
    : timeInterval && previewAmountByInterval[timeInterval];

  const range = timeInterval && rangeByInterval[timeInterval];

  const newBudgetConfigTimeInterval =
    range && type === BudgetTypes.RECURRING
      ? buildRange(range.start, range.end, timeInterval, type)
      : fixedBudgetTimeRange;

  const budgetRowsDates = useMemo(
    () =>
      isFixedBudget
        ? getBudgetRowsFixedDates(rows, forecastRows, timeIntervalForRows)
        : getBudgetRowsDates(rows, forecastRows, timeInterval),
    [isFixedBudget, rows, forecastRows, timeIntervalForRows, timeInterval]
  );

  const newTimeInterval = shouldUseRows ? budgetRowsDates : newBudgetConfigTimeInterval;

  const noBudgetDataAvailable = !budget?.data?.data;
  const budgetRowsEmpty = !shouldUseForecastRows && !shouldUseRows;
  const showSkeleton = (!budget && !newTimeInterval.length) || (budget && budgetRowsEmpty);
  const isBudgetUnavailable = !budget;
  const isRefreshedBudgetLoading = isBudgetLoading || loadingRefreshedBudget || noBudgetDataAvailable;
  const isBudgetEmpty = isBudgetUnavailable && !showSkeleton && !isRefreshedBudgetLoading;
  const isBudgetChartRowsEmpty = !isBudgetUnavailable && budgetRowsEmpty;
  const hasRefreshedRef = useRef(false);

  // Rows data points
  const rowsBudgetData = useMemo(
    () =>
      isFixedBudget
        ? getRowsValuesForFixedType(rows, 0, timeIntervalForRows)
        : getDataRowsValues(rows, timeInterval, 0),
    [isFixedBudget, rows, timeInterval, timeIntervalForRows]
  );

  // Forecasted rows data points
  const forecastRowsBudgetData = useMemo(
    () =>
      isFixedBudget
        ? getRowsValuesForFixedType(forecastRows, 1, timeIntervalForRows)
        : getDataRowsValues(forecastRows, timeInterval, 1),
    [isFixedBudget, forecastRows, timeIntervalForRows, timeInterval]
  );
  // Budget amount total data points
  const budgetAmountData = newTimeInterval.map((timestamp) => ({ x: timestamp, y: amount }));

  // Actual data points
  const actualDataRaw = newTimeInterval
    .slice(0, actualDataPreviewAmount)
    .map((timestamp, index) => ({ x: timestamp, y: index === actualDataPreviewAmount - 1 ? actual : 0 }));
  const actualData = populateActualsYValues(actualDataRaw, actual);
  const actualDataDates = actualData.map((i) => i.x);
  const lastActualData = actualData[actualData.length - 1];

  // Forecast data points
  const forecasted = budget?.data.utilization?.forecasted || 0;
  const forecastDataPeriod = newTimeInterval.slice(actualDataPreviewAmount);
  const initializedForecastDataPoints = forecastDataPeriod.map((timestamp, index) => ({
    x: timestamp,
    y: index === forecastDataPeriod.length - 1 ? forecasted : 0,
  }));

  // Forecast excluding current spend
  const exclusiveForecastValues = forecasted - lastActualData?.y;
  // Mapping cumulative forecast data points with remaining periods in budget
  const forecastData = populateForecastedYValues(
    initializedForecastDataPoints,
    exclusiveForecastValues,
    lastActualData?.y
  );
  const forecastForTimeInterval = timeInterval && filterForecastedByInterval[timeInterval](forecastData);
  const forecastWithoutDuplicates = useMemo(
    () =>
      forecastForTimeInterval?.filter(
        (forecastItem: { x: DateTime }) =>
          !actualDataDates.some((date: DateTime) =>
            typeof date === "string" ? date === forecastItem.x : date.equals(forecastItem.x)
          )
      ) || [],
    [forecastForTimeInterval, actualDataDates]
  );

  const categories = isFixedBudget
    ? (budgetRowsDates as string[])
    : transformTimestampsToCategories(newTimeInterval as DateTime[], timeInterval ?? TimeInterval.MONTH);

  const lastAlert = alerts?.[alerts.length - 1];
  const maxBudget = lastAlert?.amount === 0 ? amount : lastAlert?.amount;
  const maxBudgetByInterval = (maxBudget ?? amount) / newTimeInterval.length;

  // Budget amount to date data
  const budgetData = newTimeInterval
    .slice(0, shouldUseRows ? Object.keys(rowsBudgetData).length : actualDataPreviewAmount)
    .map((i, index: number) => ({
      x: i,
      y: maxBudgetByInterval * (index + 1),
    }));

  // Current period data
  const budgetAmountToDate = budgetData[budgetData.length - 1]?.y ?? null;
  const forecastToUse = shouldUseForecastRows ? forecastRowsBudgetData : forecastWithoutDuplicates;

  const forecastAmountForEntirePeriod =
    forecastToUse.length && type === BudgetTypes.RECURRING ? forecastToUse[forecastToUse.length - 1]?.y : forecasted;
  const variance = !isRefreshedBudgetLoading ? budgetAmountToDate - actual : null;

  const formatter = useFormatter({ aggregator: Aggregator.TOTAL, currency, metric: Metric.COST });

  useEffect(() => {
    const refreshIfNeeded = async () => {
      const foundBudget = budgets.find((b) => b.snapshot.id === budgetId);

      if (foundBudget) {
        setBudget(foundBudget);

        if (!foundBudget.data.utilization && !hasRefreshedRef.current) {
          await handleRefresh();

          hasRefreshedRef.current = true;
        }
        setIsBudgetLoading(false);
      }
    };

    refreshIfNeeded();
  }, [budgets, budgetId, handleRefresh]);

  useEffect(() => {
    if (!budgetId) {
      return;
    }
    mixpanel.track("analytics.budgets.view", { budgetId });
  }, [budgetId]);

  const budgetDimensionArray = useMemo(() => {
    const filters = budget?.data.config.filters ?? [];
    if (filters.length > 0) {
      return filters.flatMap((filter) => filter.values?.map((value) => transforms?.[filter.id]?.(value) ?? value));
    }

    const scope = getAttributionScope(budget?.data.config.scope ?? [], attributions);
    if (scope.length > 0) {
      return scope.map((scopeItem) => scopeItem?.data?.name);
    }

    return [];
  }, [budget, attributions, transforms]);

  const [isCumulativeChart, setIsCumulativeChart] = useState(true);

  const chartData = useMemo(() => {
    const actualChartData = !shouldUseRows && !shouldUseForecastRows ? actualData : shouldUseRows ? rowsBudgetData : [];
    const forecastChartData =
      !shouldUseRows && !shouldUseForecastRows
        ? (forecastWithoutDuplicates as BudgetChartData[])
        : shouldUseForecastRows
          ? (forecastRowsBudgetData as BudgetChartData[])
          : [];

    const newChartData: BudgetChartPointData = {
      actualData: actualChartData,
      budgetAmountData: [],
      budgetAmountToDateData: [],
      forecastData: forecastChartData,
    };

    if (isCumulativeChart) {
      const cumulativeActualData = getCumulativeRowsValues(actualChartData);
      const cumulativeLastDataPoint = cumulativeActualData[cumulativeActualData.length - 1]?.y;

      newChartData.actualData = cumulativeActualData;
      newChartData.budgetAmountData = budgetAmountData;
      newChartData.budgetAmountToDateData = timeInterval !== TimeInterval.DAY ? budgetData : [];
      newChartData.forecastData = getCumulativeForecastedRowsValues(forecastChartData, cumulativeLastDataPoint);
    }

    return newChartData;
  }, [
    shouldUseRows,
    rowsBudgetData,
    actualData,
    shouldUseForecastRows,
    forecastRowsBudgetData,
    forecastWithoutDuplicates,
    budgetAmountData,
    budgetData,
    isCumulativeChart,
    timeInterval,
  ]);

  const handleToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsCumulativeChart(event.target.checked);
  }, []);

  const chartContent = useMemo(() => {
    if (isRefreshedBudgetLoading) {
      return (
        <Stack
          sx={{
            position: "relative",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Skeleton animation="wave" variant="rectangular" width="100%" height={BUDGET_CHART_HEIGHT} />
        </Stack>
      );
    }

    if (isBudgetEmpty || isBudgetChartRowsEmpty) {
      return (
        <Stack
          component={Paper}
          variant="outlined"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.DATA_AVAILABLE_SOON}</Typography>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {`${capitalize(timeInterval)}ly budgets are displayed at a ${formatTimeIntervalPhrase(previewTime)} granularity. Data will start being displayed after one ${previewTime}.`}
          </Box>
        </Stack>
      );
    }

    return (
      <Box
        sx={{
          height: BUDGET_CHART_HEIGHT,
        }}
      >
        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
          <FormControlLabel
            control={<Switch checked={isCumulativeChart} onChange={handleToggleChange} />}
            label="Show cumulative"
          />
        </Box>
        <BudgetPerformanceChart
          isCumulative={isCumulativeChart}
          theme={theme}
          categories={categories}
          valueFormatter={formatter}
          actualData={chartData.actualData}
          budgetAmountData={chartData.budgetAmountData}
          budgetAmountToDateData={chartData.budgetAmountToDateData}
          forecastData={chartData.forecastData}
          alerts={alerts ?? []}
        />
      </Box>
    );
  }, [
    isRefreshedBudgetLoading,
    isBudgetEmpty,
    isBudgetChartRowsEmpty,
    isCumulativeChart,
    handleToggleChange,
    theme,
    categories,
    formatter,
    chartData.actualData,
    chartData.budgetAmountData,
    chartData.budgetAmountToDateData,
    chartData.forecastData,
    alerts,
    timeInterval,
    previewTime,
  ]);

  const errorContent = (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.DATA_AVAILABLE_SOON}</Typography>
    </Stack>
  );

  const renderDefinitionListItems = (items: BudgetDefinitionListItem[]): ReactNode =>
    items.map(({ term, value, loading, color }) => (
      <Fragment key={term}>
        <DefinitionListTerm>
          <LoadingBudgetCardWrapper loading={loading} wrapperWidth="100%">
            {term}
          </LoadingBudgetCardWrapper>
        </DefinitionListTerm>

        <DefinitionListDesc>
          <LoadingBudgetCardWrapper loading={loading} wrapperWidth="60%">
            {color ? <span style={{ color }}>{value}</span> : value}
          </LoadingBudgetCardWrapper>
        </DefinitionListDesc>
      </Fragment>
    ));

  const budgetSummaryItems = [
    { term: budgetTxt.BUDGET_AMOUNT_TERM, value: formatBudgetValue(amount, currency), loading: isBudgetUnavailable },
    {
      term: isFixedBudget ? budgetTxt.FIXED_TYPE : budgetTxt.TYPE_AND_FREQUENCY,
      value: getFrequencyPhrase(timeInterval ?? TimeInterval.MONTH, frequency, isFixedBudget),
      loading: isBudgetUnavailable,
    },
    { term: budgetDateTerm, value: budgetDates, loading: isBudgetUnavailable },
    {
      term: budgetTxt.DIMENSION,
      value: (
        <>
          {budgetDimensionArray.slice(0, MAX_DIMENSIONS_NUM).join(", ")}
          {budgetDimensionArray.length > MAX_DIMENSIONS_NUM && ` +${budgetDimensionArray.length - MAX_DIMENSIONS_NUM}`}
        </>
      ),
      loading: isBudgetUnavailable,
    },
    {
      term: budgetTxt.AMORTIZED_COST,
      value: amortizedCost ? globalText.YES : globalText.NO,
      loading: isBudgetUnavailable,
    },
  ];

  const currentPeriodItems = [
    {
      term: budgetTxt.BUDGET_AMOUNT_TO_DATE_TERM,
      value: formatBudgetValue(timeInterval !== TimeInterval.DAY ? budgetAmountToDate : amount, currency),
      loading: isRefreshedBudgetLoading,
    },
    { term: budgetTxt.ACTUALS_TO_DATE, value: formatBudgetValue(actual, currency), loading: isRefreshedBudgetLoading },
    {
      term: budgetTxt.VARIANS_TO_DATE,
      value:
        variance !== null ? (
          <span style={{ color: variance < 0 ? varianceNegativeColor : variancePositiveColor }}>
            {formatBudgetValue(variance, currency)}
          </span>
        ) : (
          budgetTxt.DATA_LOADING
        ),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_AMOUNT_FOR_ENTIRE_PERIOD,
      value: formatBudgetValue(forecastAmountForEntirePeriod, currency),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_TO_HIT_BUDGET_AMOUNT,
      value: forecastedDate
        ? isForecastedDateInBudgetRange(DateTime.fromJSDate(forecastedDate.toDate()), startPeriod, endPeriod)
          ? DateTime.fromJSDate(forecastedDate.toDate()).toFormat("d MMMM yyyy")
          : budgetTxt.NO_FORECASTED_DATE
        : budgetTxt.NO_FORECASTED_DATE,
      loading: isRefreshedBudgetLoading,
    },
  ];

  return (
    <>
      {budget && <ViewBudgetHeader budget={budget} />}
      <Stack
        data-cy="view-budget-content"
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          gap: 2.4,
        }}
      >
        {chartContent}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: "stretch",
            mt: 6,
          }}
        >
          <MetadataCard
            title={budgetTxt.BUDGET_SUMMARY}
            loading={isBudgetUnavailable}
            error={isBudgetEmpty}
            errorContent={errorContent}
          >
            <DefinitionList>{renderDefinitionListItems(budgetSummaryItems)}</DefinitionList>
          </MetadataCard>

          <MetadataCard
            title={isFixedBudget ? budgetTxt.FIXED_BUDGET_PERIOD_TERM : budgetTxt.CURRENT_PERIOD}
            loading={isBudgetUnavailable}
            error={isBudgetEmpty}
            errorContent={errorContent}
          >
            <DefinitionList>{renderDefinitionListItems(currentPeriodItems)}</DefinitionList>
          </MetadataCard>
        </Stack>
      </Stack>
    </>
  );
};
