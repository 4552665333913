import { DoitRole } from "@doitintl/cmp-models";

import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../Context/AuthContext";
import { useTier } from "../../Context/TierProvider";
import { CloudFlowEditor } from "./CloudflowBuilder/CloudflowEditor";
import CloudflowHistory from "./CloudflowHistory/CloudflowHistory";
import CloudflowRunDetails from "./CloudflowRunDetails/CloudflowRunDetails";
import CloudflowTemplates from "./CloudflowTemplates/CloudflowTemplates";
import Dashboard from "./Dashboard";
import { useRegisteredInterest } from "./hooks";
import LandingPage from "./LandingPage";

const Cloudflow = ({ pageId }) => {
  const { onRegisterInterest, isRegistered, isRegisteredLoading } = useRegisteredInterest();
  const { isDoitEmployee } = useAuthContext();
  const { loading: tiersLoading, isFeatureEntitled } = useTier();
  const isCloudflowEntitled = isFeatureEntitled("governance:cloudflow", true);

  // TODO: Uncomment this when feature is ready for release, then update "canSeeCloudflow" accordingly
  // const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  // const hasCloudFlowPermission = userRoles.has(UserPermissions.CloudFlowViewer);

  const hasCloudflowDoitRole = useDoitRoleCheck(DoitRole.Cloudflow);
  const canSeeCloudflow = isDoitEmployee && hasCloudflowDoitRole;

  if (tiersLoading) {
    return null;
  }

  if (canSeeCloudflow && isCloudflowEntitled) {
    switch (pageId) {
      case "cloudflow:edit":
        return <CloudFlowEditor />;
      case "cloudflow:history":
        return <CloudflowHistory />;
      case "cloudflow:templates":
        return <CloudflowTemplates />;
      case "cloudflow:details":
        return <CloudflowRunDetails />;
      default:
        return <Dashboard pageId={pageId} />;
    }
  }

  if (isRegisteredLoading) {
    return null;
  }

  return <LandingPage onRegisterInterest={onRegisterInterest} isRegistered={isRegistered} />;
};

export default Cloudflow;
