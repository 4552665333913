import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  AssetTypeGoogleCloud,
  CategoryStatus,
  type CloudConnectGoogleCloud,
  CustomerModel,
} from "@doitintl/cmp-models";
import { getCollection, type TransformMethod, useCollectionData } from "@doitintl/models-firestore";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import { CircularProgressLoader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getCachingKeys } from "../../../utils/cachingKeys";
import { capitalizeStartCase } from "../../../utils/common";
import { EllipsisCell } from "./EllipsisCell";
import { LimitedListWithTooltip } from "./LimitedListWithTooltip";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";
import { filters, headerColumns } from "./table";
import { type EnhancedCloudConnectGoogleCloud } from "./types";
import { useCloudConnectHealthCheck } from "./useCloudConnectHealthCheck";
import { useDeleteServiceAccount } from "./useDeleteServiceAccount";
import { usePermissionCategories } from "./usePermissionCategories";

export const ServiceAccountsPage = () => {
  const { customer } = useCustomerContext();
  const categories = usePermissionCategories();
  const history = useHistory();

  // TODO(laura): there's something weird with the types here, we can probably sort this out easier once we remove V1
  // @ts-expect-error
  const transformSnapshot: TransformMethod<CloudConnectGoogleCloud, EnhancedCloudConnectGoogleCloud> = useCallback(
    (cloudConnectDoc, snapshot) => ({
      data: {
        ...cloudConnectDoc,

        // Default to "organization"
        scope: cloudConnectDoc.scope || "organization",
      },
      id: snapshot.id,

      // Display the correct name depending on whether we have an org or a project
      organizationOrProjectId:
        cloudConnectDoc.scope === "project"
          ? cloudConnectDoc.projectId || "N/A"
          : cloudConnectDoc.organizations
            ? cloudConnectDoc.organizations[0]?.displayName
            : "N/A",

      // 1. Get all enabled features
      // 2. Order them alphabetically but make sure we put "core" first
      // 3. Map them to their display names
      // 4. Remove empty names (categories still in the document but not available anymore)
      enabledFeatures: Object.entries(cloudConnectDoc.categoriesStatus)
        .filter(([_, status]) => status === CategoryStatus.Healthy)
        .toSorted(([keyA], [keyB]) => (keyA === "core" ? -1 : keyA.localeCompare(keyB)))
        .map(([key]) => categories.find((category) => category.id === key)?.name || "")
        .filter((name) => !!name),
    }),
    [categories]
  );

  const [cloudConnectDocs, isLoadingCloudConnectDocs] = useCollectionData(
    getCollection(CustomerModel)
      .doc(customer.id)
      .collection("cloudConnect")
      .where("cloudPlatform", "==", AssetTypeGoogleCloud)
      .narrow<CloudConnectGoogleCloud>(),
    {
      transform: transformSnapshot,
      caching: true,
      cachingKeys: getCachingKeys(customer.id),
    }
  );

  const [deletionPrompt, setDeletionPrompt] = useState<EnhancedCloudConnectGoogleCloud | null>(null);

  const [isDeletionInProgress, deleteServiceAccount] = useDeleteServiceAccount();
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  // Run health check on page load
  useCloudConnectHealthCheck(customer.id);

  const onConfirmDelete = async () => {
    if (!deletionPrompt) {
      return;
    }

    try {
      await deleteServiceAccount(deletionPrompt);
      setDeletionPrompt(null);
      showSuccess("Service account has been successfully deleted");
    } catch {
      showError("An error occurred during service account deletion");
    }
  };

  return (
    <>
      <Box p={2}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="h1">Google Cloud organizations and projects</Typography>

          {/* Organization is the primary CTA */}
          <Stack direction={{ xs: "column", sm: "row" }} gap={[1, 2]}>
            <Button
              variant="contained"
              component={Link}
              to={`/customers/${customer.id}/settings/gcp/connect/organization`}
              sx={{ whiteSpace: "nowrap" }}
            >
              Connect a new organization
            </Button>

            <Button
              variant="outlined"
              component={Link}
              to={`/customers/${customer.id}/settings/gcp/connect/project`}
              sx={{ whiteSpace: "nowrap" }}
            >
              Connect a new project
            </Button>
          </Stack>
        </Stack>

        {isLoadingCloudConnectDocs ? (
          <CircularProgressLoader />
        ) : (
          <FilterTable
            rowComponent={({ row }) => {
              const editPath = `/customers/${customer.id}/settings/gcp/connect/${row.data.scope}/${row.id}`;

              return (
                <CellsWrapper>
                  <TableCell data-cy="org-cell">
                    <Typography component={Link} to={editPath} color="inherit" variant="body2">
                      {row.organizationOrProjectId}
                    </Typography>
                  </TableCell>

                  <Hide mdDown>
                    {/* This might get very long, make sure it's one of the first columns that shrinks, but doesn't break line */}
                    <EllipsisCell maxWidth="250px" data-cy="email-cell">
                      {row.data.clientEmail}
                    </EllipsisCell>

                    <TableCell>{capitalizeStartCase(row.data.scope)}</TableCell>
                    <TableCell>
                      <LimitedListWithTooltip items={row.enabledFeatures} limit={2} title="Enabled features" />
                    </TableCell>
                  </Hide>

                  <TableCell data-cy="status-cell">
                    <ServiceAccountStatusChip status={row.data.status} />
                  </TableCell>

                  <TableCell padding="checkbox">
                    <ThreeDotsMenu
                      options={[
                        {
                          key: "edit",
                          label: "Edit",
                          dataCy: "edit",
                          action: () => {
                            history.push(editPath);
                          },
                        },
                        {
                          key: "delete",
                          label: "Delete connection",
                          dataCy: "delete",
                          color: "error.main",
                          action: () => {
                            setDeletionPrompt(row);
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </CellsWrapper>
              );
            }}
            tableItems={cloudConnectDocs}
            headerColumns={headerColumns}
            filterColumns={filters}
            filterBarPlaceholder="Filter organizations and projects"
            filterAreaSXProp={{ mb: 3 }}
            emptyTableComponent={
              <Typography variant="body2" textAlign="center" my={3} sx={{ opacity: 0.6 }}>
                No organizations or projects have been connected
              </Typography>
            }
            emptyTableCellBorder={false}
            defaultSortingColumnValue="data.status"
            defaultSortDirection="desc"
            rowDataCyId={true}
          />
        )}
      </Box>
      <Dialog
        open={deletionPrompt !== null}
        onClose={() => {
          setDeletionPrompt(null);
        }}
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          {/* TODO(laura): Update this to be dynamic depending on the enabled features */}
          Removing the integration with Google Cloud will disable the following features:
          <ul>
            <li>BigQuery Lens</li>
            <li>IAM Folder support in Cloud Analytics</li>
            <li>Disposable Cloud Environments (DCEs)</li>
            <li>Compute Rightsizing Insights & Recommendations</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeletionPrompt(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onConfirmDelete}
            variant="contained"
            color="error"
            loading={isDeletionInProgress}
            mixpanelEventId="settings.gcp-confirmation.confirm"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
