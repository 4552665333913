import "@services/clouddiagrams/frontend/sass/ind/_cmp_dashboard.scss";

// import { useEffect } from "react";
// import axios from "axios";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import awsLogoDark from "../../../assets/amazon-web-services-logo-white.png";
import awsLogo from "../../../assets/amazon-web-services-new-square.png";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

// import { useAuthContext } from "@client/src/Context/AuthContext";
// import { useAWSAccounts } from "@client/src/Pages/Settings/AWS/hooks";

// const tempRequest = (currentUser) => {
//   const url =
//     "https://dev.livediagrams.net/api/scheme/stats?start=2025-02-01T20:01:40.569Z&end=2025-02-09T20:01:40.569Z";

//   const headers = {
//     "Cache-Control": "no-cache",
//     Authorization: `Bearer ${currentUser.accessToken}`,
//   };

//   axios
//     .get(url, { headers })
//     .then((response) => {
//       console.log("Response:", response.data);
//     })
//     .catch((error) => {
//       console.error("Error:", error.response ? error.response.status : error.message);
//     });
// };

const Head = ({ handleCreateDiagram }) => {
  return (
    <Grid
      container
      spacing={1}
      size="grow"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid sx={{ paddingTop: "1rem" }}>
        <Typography
          data-cy="clouddiagrams-title"
          variant="h1"
          sx={{
            mb: 1,
          }}
        >
          Cloud diagrams
        </Typography>
        <Typography
          data-cy="clouddiagrams-description"
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 400,
            mb: 2,
          }}
        >
          Visualize and analyze your cloud infrastructure
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <TextField
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleCreateDiagram} sx={{ marginLeft: "1rem" }}>
          Create diagram
        </Button>
      </Grid>
    </Grid>
  );
};

const BorderBox = ({ children, isAwsLogo }) => {
  return (
    <Box className="__item" sx={{ borderColor: "divider" }}>
      <Box className="__text" sx={{ borderColor: isAwsLogo ? "transparent" : "text.secondary" }}>
        {children}
      </Box>
    </Box>
  );
};

const DiagramCard = () => {
  const darkMode = useDarkThemeCheck();

  return (
    <Card sx={{ minWidth: 100 }}>
      <CardContent sx={{ padding: "12px 16px 16px" }} gutterBottom>
        <Typography variant="h6" component="div">
          Dev diagram - Brad
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
          32423423423 (rebl-dev)
        </Typography>
        <Typography variant="body2" className="cd-dashboard-card-bottomline">
          <BorderBox {...{ isAwsLogo: true }}>
            <img src={darkMode ? awsLogoDark : awsLogo} width={24} alt="aws logo" style={{ marginBottom: "-3px" }} />
          </BorderBox>
          <BorderBox>All services</BorderBox>
          <BorderBox>2 changes</BorderBox>
        </Typography>
      </CardContent>
    </Card>
  );
};

const handleCreateDiagram = () => {
  return false;
};

const CloudDiagramsDashboard = () => {
  // const { currentUser } = useAuthContext({ mustHaveUser: true });
  // const [accounts] = useAWSAccounts();

  // useEffect(() => {
  //   // tempRequest(currentUser);
  //   console.log(currentUser);
  // }, []);

  return (
    <>
      <Head {...{ handleCreateDiagram }} />
      <Grid container spacing={2} sx={{ padding: "0.5rem 0" }}>
        {[...Array(6)].map((_, i) => (
          <Grid size={{ xs: 6, md: 4 }} key={i}>
            <DiagramCard />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CloudDiagramsDashboard;
