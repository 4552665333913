import { useCallback, useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router";
import { CloudAnalyticsModel, Roles } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";

import { analyticsAlertText, globalText } from "../../../assets/texts";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useAlerts } from "../../../Components/hooks/cloudAnalytics/alerts/useAlerts";
import { useInitializeAlertsConditions } from "../../../Components/hooks/cloudAnalytics/alerts/useInitializeAlertsConditions";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { Comparators } from "../../../types/FilterTable";
import { CSPCustomerID } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { alertsToAnalyticsResources } from "../analyticsResources/utils";
import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import EditPermissionsButton from "../labels/components/EditPermissionsButton";
import { useLabels } from "../labels/hooks";
import {
  CloudAnalyticsEntities,
  getCloudFromAssets,
  useCanEditSelectedObjects,
  useCanEditSelectedObjectsPermissions,
  useCustomerIntegrations,
} from "../utilities";
import { filters, headerColumns } from "./AnalyticsAlertsColumns";
import { AlertRow } from "./AnalyticsAlertsRow";
import { useCreateAlertHandler, useDeleteHandler, useShareHandler } from "./hooks";
import { type AnalyticsAlertWithRef } from "./types";

export type AnalyticsAlertsBrowserProps = {
  alertList: AnalyticsAlertWithRef[];
  alertsLoading: boolean;
};

export const AnalyticsAlertsBrowser = () => {
  const routeMatchURL = useRouteMatchURL();
  const location = useLocation();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();

  const [alertList, alertsLoading] = useAlerts();
  const [labels, labelsLoading] = useLabels();

  const alerts = useMemo(() => alertList.filter((alert) => alert.data.isValid), [alertList]);
  const [clickedRow, setClickedRow] = useState<AnalyticsAlertWithRef>({} as AnalyticsAlertWithRef);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selected, setSelected] = useState<AnalyticsAlertWithRef[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();
  const { dimensions } = useAnalyticsDimensions({ metadataSnapshots });
  const [extendedMetrics, extendedMetricsLoading] = useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("configs").collection("cloudAnalyticsConfigs").doc("extended-metrics")
  );

  const { customerIntegrations } = useCustomerIntegrations();

  const filteredExtendedMetrics = useMemo(() => {
    const cloud = getCloudFromAssets(customer.assets);
    const visibility = customer.id === CSPCustomerID ? "csp" : "customer";

    return extendedMetrics?.metrics?.filter((metric) => {
      if (!!metric.cloud && !!cloud && metric.cloud !== cloud && !customerIntegrations?.includes(metric.cloud)) {
        return false;
      }

      return metric.visibility === "all" || metric.visibility === visibility;
    });
  }, [customer.assets, customer.id, customerIntegrations, extendedMetrics?.metrics]);

  const isBulk = selected.length >= 1;

  useInitializeAlertsConditions(alerts, dimensions ?? [], filteredExtendedMetrics ?? []);

  useEffect(() => {
    const updatedSelectedAlerts = alerts.filter((alert) => selected.some((x) => x.ref.id === alert.ref.id));

    setSelected(updatedSelectedAlerts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  const handleNewAlert = useCreateAlertHandler({ mixpanelEventName: "analytics.alerts.new", baseUrl: routeMatchURL });
  const handleDeleteAlert = useDeleteHandler({
    clickedRow,
    selected,
    closeDialog: () => {
      setClickedRow({} as AnalyticsAlertWithRef);
      setSelected([]);
    },
  });

  useEffect(() => {
    mixpanel.track("analytics.alerts.list");
  }, []);

  const AlertRowWrapper = useCallback(
    ({ row }: { row: AnalyticsAlertWithRef }) => (
      <AlertRow
        setOpenDeleteDialog={setOpenDeleteDialog}
        setShareDialogOpen={setShareDialogOpen}
        setClickedRow={setClickedRow}
        row={row}
        labels={labels}
        dimensions={dimensions ?? []}
      />
    ),
    [dimensions, labels]
  );

  const hasOtherOwner = useMemo(
    () =>
      selected?.some((s) => s.data.collaborators.find((c) => c.email !== currentUser.email && c.role === Roles.OWNER)),
    [currentUser.email, selected]
  );

  const disableDeleteAlert = !selected.length || hasOtherOwner;

  const handleChangeSharing = useShareHandler({
    clickedRow,
    selected,
    setLoading: setShareLoading,
    closeDialog: () => {
      setShareDialogOpen(false);
      setClickedRow({} as AnalyticsAlertWithRef);
    },
  });

  const cantEditSelectedAlerts = useCanEditSelectedObjects(
    currentUser.email,
    selected.map((x) => x.data)
  );

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    selected.map((x) => x.data)
  );

  const qsNameFilter = new URLSearchParams(location.search).get("name") ?? "";
  const defaultFilters = qsNameFilter
    ? [
        {
          column: filters[1],
          comparator: Comparators.EQUAL_TO,
          value: qsNameFilter,
          label: qsNameFilter,
        },
      ]
    : undefined;

  if (extendedMetricsLoading || alertsLoading) {
    return <CircularProgressLoader />;
  }

  if (labelsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      <FilterTable<AnalyticsAlertWithRef>
        toolbarProps={{
          title: "Alerts",
          allowToEditColumns: true,
          deleteButton: {
            text: globalText.DELETE,
            onClick: () => {
              setOpenDeleteDialog(true);
            },
            tooltipTitle: hasOtherOwner ? analyticsAlertText.ALERT_NOT_OWNER : "",
            disabled: disableDeleteAlert,
          },
          customSlot: (
            <>
              <Grid>
                <Button variant="contained" color="primary" onClick={handleNewAlert}>
                  {analyticsAlertText.CREATE_NEW_ALERT}
                </Button>
              </Grid>
              <Grid>
                <AssignLabelsButton
                  labels={labels ?? []}
                  selectedResources={alertsToAnalyticsResources(selected)}
                  disabled={!cantEditSelectedAlerts}
                />
              </Grid>
              <Grid>
                <EditPermissionsButton
                  selectedResources={alertsToAnalyticsResources(selected)}
                  onClick={() => {
                    setShareDialogOpen(true);
                  }}
                  disabled={!canEditPermissions}
                />
              </Grid>
            </>
          ),
        }}
        key={qsNameFilter}
        showRowsSelection={true}
        onRowsSelected={setSelected}
        tableItems={alerts}
        rowComponent={AlertRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filters}
        filterBarPlaceholder={analyticsAlertText.FILTER_ALERTS}
        persistenceKey="analytics_alerts_tab"
        itemUniqIdentifierField="ref.id"
        defaultSortingColumnValue="data.timeLastAlerted"
        defaultFilters={defaultFilters}
      />
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={analyticsAlertText.DELETE_SELECTED}
          message={analyticsAlertText.DELETE_MESSAGE}
          onDelete={handleDeleteAlert}
          onClose={() => {
            setOpenDeleteDialog(false);
          }}
        />
      )}
      {shareDialogOpen && (
        <ShareDialog
          open={shareDialogOpen}
          title={isBulk ? analyticsAlertText.SHARE_ALERTS : analyticsAlertText.SHARE_ALERT}
          onClose={() => {
            setShareDialogOpen(false);
            setClickedRow({} as AnalyticsAlertWithRef);
          }}
          entity={CloudAnalyticsEntities.ALERT}
          handleChangeSharing={handleChangeSharing}
          loading={shareLoading}
          shareEntities={isBulk ? selected.map((x) => x.data) : [clickedRow.data]}
        />
      )}
    </>
  );
};

export default AnalyticsAlertsBrowser;
