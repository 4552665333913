import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Button, Card, Stack, Typography, useTheme } from "@mui/material";

import { useCloudflowOperations } from "../../Common/CloudflowOperationsProvider";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { NodeCardHeader } from "./NodeCardHeader";

export const ManualTriggerTab = () => {
  const { onChangeTriggerType } = useNodeConfigurationContext<CloudFlowNodeType.MANUAL_TRIGGER>();
  const { httpOperationLoading } = useCloudflowOperations();

  const {
    palette: { primary },
  } = useTheme();

  return (
    <Box>
      <Stack
        sx={{
          p: 2,
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Card sx={{ "&:hover": { borderColor: primary.main } }}>
          <NodeCardHeader
            avatar={<AccountCircleIcon color="primary" />}
            subheader="Triggered manually by the user"
            title="Manual"
            action={
              <Button onClick={onChangeTriggerType} disabled={httpOperationLoading}>
                Change
              </Button>
            }
          />
        </Card>
        <Typography
          variant="subtitle2"
          sx={{
            color: "text.primary",
            fontWeight: 500,
          }}
        >
          About Manual CloudFlow triggers
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          A manual trigger allows you to start this CloudFlow whenever you choose. When a CloudFlow is published, you
          can trigger it by clicking the ‘Run’ button in the top bar of the CloudFlow editor.
        </Typography>
      </Stack>
    </Box>
  );
};
